var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lecturer"},[_c('TopBar',{attrs:{"type":2,"pageTitle":'我的公开考试'}}),(_vm.commonExamList.length > 0)?_c('div',{staticClass:"examCont"},[_c('ul',{staticClass:"test-list"},[_vm._l((_vm.commonExamList),function(item){return _c('li',{key:item.id,staticClass:"test-info"},[_c('div',{staticClass:"test-title vertical-ellipsis"},[_vm._v(_vm._s(item.exam_name))]),_c('div',{staticClass:"test-date"},[_vm._v(" "+_vm._s(item.start_date)+" — "+_vm._s(item.end_date)+" ")]),_c('div',{staticClass:"btns"},[_c('div',{staticClass:"residue"},[_vm._v(" 剩余考试次数："),_c('span',{staticClass:"num"},[_vm._v(_vm._s(item.remaining_exam_count)+"次")])]),_c('router-link',{staticClass:"button blue",attrs:{"to":'/commonExam/text/' + item.id}},[_vm._v("去考试")]),_c('button',{staticClass:"button grey",on:{"click":function($event){return _vm.viewScoreBtn(item.id, item.exam_name)}}},[_vm._v(" 查看分数 ")])],1)])}),(
          _vm.classExam &&
          _vm.classExam.length == 0 &&
          _vm.classInfo &&
          _vm.classInfo.id != '302'
        )?_c('li',{staticClass:"no-data"},[_vm._v(" 班级内还没有考试 ")]):_vm._e()],2)]):_vm._e(),(_vm.num > 0)?_c('pagination',{attrs:{"num":_vm.num,"limit":_vm.limit},on:{"getNew":_vm.getNew}}):_vm._e(),(
      _vm.commonExamList.length == 0
    )?_c('div',{staticClass:"nodata"},[_c('img',{staticClass:"nodata-img",attrs:{"src":require('@/assets/no-data.png')}}),_c('p',{staticClass:"nodata-text"},[_vm._v("暂无数据")])]):_vm._e(),(_vm.viewScoreState)?_c('div',{staticClass:"view-score"},[_c('div',{staticClass:"score-content"},[_c('div',{staticClass:"close",on:{"click":function($event){_vm.viewScoreState = false}}}),_c('div',{staticClass:"score-title"},[_vm._v(_vm._s(_vm.viewScoreTitle))]),_c('ul',{staticClass:"my-table"},[_vm._m(0),_vm._l((_vm.queryScore),function(item){return _c('li',{key:item.created_at,staticClass:"table-tr"},[_c('span',{staticClass:"table-td",staticStyle:{"width":"58px"}},[_vm._v(" "+_vm._s(item.id)+" ")]),_c('span',{staticClass:"table-td",staticStyle:{"width":"170px"}},[_vm._v(_vm._s(item.created_at))]),_c('span',{staticClass:"table-td",staticStyle:{"width":"90px"}},[_vm._v(_vm._s(item.score))]),_c('p',{staticClass:"btn",on:{"click":function($event){return _vm.examDetail(item)}}},[_vm._v("查看")])])}),(_vm.queryScore.length == 0)?_c('li',{staticClass:"table-tr nodata"},[_c('span',{staticClass:"table-td"},[_vm._v("暂无分数")])]):_vm._e()],2)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"table-th"},[_c('span',{staticClass:"table-td",staticStyle:{"width":"58px"}},[_vm._v("id")]),_c('span',{staticClass:"table-td",staticStyle:{"width":"170px"}},[_vm._v("考试时间")]),_c('span',{staticClass:"table-td",staticStyle:{"width":"90px"}},[_vm._v("分数")])])
}]

export { render, staticRenderFns }