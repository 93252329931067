<template>
  <div class="lecturer">
    <TopBar :type="2" :pageTitle="'我的公开考试'" />
    <div class="examCont" v-if="commonExamList.length > 0">
      <ul class="test-list">
        <li class="test-info" v-for="item in commonExamList" :key="item.id">
          <div class="test-title vertical-ellipsis">{{ item.exam_name }}</div>
          <div class="test-date">
            {{ item.start_date }} — {{ item.end_date }}
          </div>
          <div class="btns">
            <div class="residue">
              剩余考试次数：<span class="num"
                >{{ item.remaining_exam_count }}次</span
              >
            </div>
            <router-link
              :to="'/commonExam/text/' + item.id "
              class="button blue"
              >去考试</router-link
            >
            <button
              class="button grey"
              @click="viewScoreBtn(item.id, item.exam_name)"
            >
              查看分数
            </button>
          </div>
        </li>
        <li
          class="no-data"
          v-if="
            classExam &&
            classExam.length == 0 &&
            classInfo &&
            classInfo.id != '302'
          "
        >
          班级内还没有考试
        </li>
      </ul>
    </div>


    <pagination
      v-if="num > 0"
      :num="num"
      :limit="limit"
      @getNew="getNew"
    ></pagination>

    <div
      class="nodata"
      v-if="
        commonExamList.length == 0
      "
    >
      <img class="nodata-img" :src="require('@/assets/no-data.png')" />
      <p class="nodata-text">暂无数据</p>
    </div>

    <!-- 查看分数 -->
    <div class="view-score" v-if="viewScoreState">
      <div class="score-content">
        <div class="close" @click="viewScoreState = false"></div>
        <div class="score-title">{{ viewScoreTitle }}</div>
        <ul class="my-table">
          <li class="table-th">
            <span class="table-td" style="width: 58px">id</span>
            <span class="table-td" style="width: 170px">考试时间</span>
            <span class="table-td" style="width: 90px">分数</span>
          </li>
          <li
            class="table-tr"
            v-for="item in queryScore"
            :key="item.created_at"
          >
            <span class="table-td" style="width: 58px">
              <!-- 第{{ index + 1 }}次考试 -->
              {{ item.id }}
            </span>
            <span class="table-td" style="width: 170px">{{
              item.created_at
            }}</span>
            <span class="table-td" style="width: 90px">{{ item.score }}</span>
            <p class="btn" @click="examDetail(item)">查看</p>
          </li>
          <li class="table-tr nodata" v-if="queryScore.length == 0">
            <span class="table-td">暂无分数</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
import TopBar from "@/m/common/topBar.vue";
export default {
  name: "lecturer",
  data() {
    return {
      num: 0, //数据总个数
      limit: 12, //一页显示几个
      nowPage: 1, //当前页码
      commonExamList: [], //公开考试列表
      viewScoreState: false, //查看分数弹框状态
      viewScoreTitle: "",
      queryScore: [], //查看分数列表
    };
  },
  components: {
    pagination,
    TopBar,
  },
  mounted() {
    this.getOpenExam(); //获取讲师列表
  },
  methods: {
    //获取公开考试列表
    getOpenExam() {
      this.$axios
        .post(
          `/v1/commonExam/myCommonExam`,
          { page: this.nowPage, per_page: this.limit },
          { useLog: true }
        )
        .then((res) => {
          if (res.code == 0) {
            this.commonExamList = res.data.data;
            this.num = res.data.total;
          }
        });
    },
    //查看分数
    viewScoreBtn(id, name) {
      this.$axios
        .post(
          `/v1/commonExam/queryScore`,
          {
            exam_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          this.viewScoreState = true;
          this.viewScoreTitle = name;
          this.queryScore = res.data;
        });
    },
    // 查看考试详情
    examDetail(row) {
      this.$router.push("/userCenter/commExamDetail?id=" + row.id);
    },
    // 跳转到考试
    goExam(item) {
      this.$router.push("/commonExam/text/" + item.id);
    },
    //翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getOpenExam();
    },
  },
};
</script>
<style scoped lang="scss">
.examCont {
  // width: 1200px;
  // margin: 40px auto;
  margin-top: 30px;
  .test-info {
    box-sizing: border-box;
    width: 95%;
    padding: 38px 28px;
    box-shadow: 0px 0px 10px 0px rgba(0, 12, 40, 0.14);
    border-radius: 6px;
    margin: 0 auto 28px;
    .test-title {
      width: 100%;
      max-height: 70px;
      font-size: 28px;
      color: #333;
      line-height: 35px;
    }
    .test-date {
      font-size: 24px;
      height: 30px;
      line-height: 30px;
      color: #999;
      padding-left: 40px;
      background: url(~@/assets/clock-ico.png) no-repeat left center #fff;
      background-size: auto 30px;
      margin-top: 20px;
    }
    .btns {
      overflow: hidden;
      margin-top: 48px;
      .button {
        display: block;
        box-sizing: border-box;
        padding: 0;
        width: 128px;
        height: 52px;
        border-radius: 6px;
        line-height: 52px;
        font-size: 26px;
        text-align: center;
        float: right;
        margin-left: 20px;
        &.grey {
          border: 1px solid #999;
          color: #666;
        }
      }
    }
    .residue {
      font-size: 24px;
      color: #999;
      line-height: 52px;
      float: left;
      .num {
        color: #333;
      }
    }
  }
}
.page-wrap {
  padding-bottom: 30px;
}

// 查看分数
.view-score {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99;
  .score-content {
    width: 100%;
    max-height: 600px;
    padding: 30px;
    background-color: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    .score-title {
      font-size: 24px;
      font-weight: 900;
      color: #333;
      margin-bottom: 20px;
    }
    .my-table {
      max-height: 500px;
      overflow-y: auto;
    }
    .close {
      width: 30px;
      height: 30px;
      background: url(~@/assets/close-grey-ico.png) no-repeat;
      background-size: 100%;
      position: absolute;
      right: 80px;
      top: 10px;
      cursor: pointer;
    }
    p.btn {
      cursor: pointer;
      color: #254ed4;
    }
  }
}

.nodata {
  width: 574px;
  padding-top: 20px;
  margin: 0 auto;
  text-align: center;
  .nodata-img {
    width: 100%;
    height: auto;
  }
  .nodata-text {
    font-size: 26px;
    color: #999;
    padding-top: 40px;
  }
}
</style>